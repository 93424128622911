<template>
  <v-modal name="reward-suggestion-dialog" @before-open="setReward" @before-close="resetForm" height="auto" width="400">
    <v-card :loading="isLoading">
      <v-card-title class="headline" primary-title>
        {{ isNewReward ? 'Jutalom létrehozása' : 'Jutalom szerkesztése' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <div class="upload-image">
            <label class="form-label" for="image-to-upload" :style="rewardImage">
              <span>
                <v-img width="50" src="@/assets/images/upload-gray.svg" alt="upload-cloud" />
              </span>
              Kép {{ reward.image ? 'cseréje' : 'feltöltése' }}
            </label>
            <input
              class="d-none"
              type="file"
              id="image-to-upload"
              name="image-to-upload"
              data-target="image"
              @change="uploadImage"
            />
            <v-text-field
              solo
              flat
              type="hidden"
              id="image"
              name="image"
              v-model="reward.image"
              :rules="[requiredRule]"
            ></v-text-field>
          </div>
          <v-text-field v-model="reward.name" label="Név" :rules="[requiredRule]"></v-text-field>
          <v-textarea v-model="reward.description" label="Leírás" :rules="[requiredRule]"></v-textarea>
          <v-text-field v-model="reward.url" label="További információk URL"></v-text-field>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="reward.cost" label="Érték" type="number" :rules="[requiredRule]"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-switch v-model="reward.isApprovalRequired" label="Jóváhagyás köteles"></v-switch>
            </v-col>
          </v-row>
          <v-select v-model="reward.language" :items="languages" label="Nyelv" :rules="[requiredRule]"></v-select>
          <v-combobox v-model="reward.category" :items="categories" label="Kategória"></v-combobox>
          <v-text-field v-model="reward.stock" label="Készlet"></v-text-field>
          <v-switch v-model="reward.highlighted" label="Kiemelt"></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="closeDialog">Bezárás</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click="submit">Mentés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
import { diff } from 'deep-object-diff'

import { CREATE_REWARD_SUGGESTION, UPDATE_REWARD_SUGGESTION, FILE_UPLOAD } from '@/store/types'
import formControl from '@/mixins/formControl'
import { mapState } from "vuex";

const INITIAL_STATE = {
  category: '',
  name: '',
  description: '',
  url: '',
  image: null,
  cost: null,
  isApprovalRequired: false,
  language: 'hu',
  stock: null,
  highlighted: false
}

export default {
  name: 'RewardSuggestionsDialog',
  mixins: [formControl],
  data() {
    return {
      reward: { ...INITIAL_STATE },
      editReward: null,
      isLoading: false,
      isNewReward: null,
      languages: [
        {
          text: 'Magyar',
          value: 'hu'
        },
        {
          text: 'Angol',
          value: 'en'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      categories: state => state.rewardSuggestions.categories
    }),
    rewardImage() {
      return { backgroundImage: `url(${this.reward.image})` }
    }
  },
  methods: {
    setReward(event) {
      this.isNewReward = event.params.isNewReward
      this.reward = event.params.reward ? { ...event.params.reward } : { ...INITIAL_STATE }

      if (!this.isNewReward) {
        this.editReward = event.params.reward
      }
    },
    resetForm() {
      this.trunk = { ...INITIAL_STATE }
      this.isNewReward = null
    },
    closeDialog() {
      this.$modal.hide('reward-suggestion-dialog')
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    },
    async uploadImage(event) {
      this.toggleLoading()

      try {
        const formData = new FormData()
        formData.append('aimage', event.target.files[0])

        const image = await this.$store.dispatch(FILE_UPLOAD, formData)

        this.reward.image = image.path
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async saveReward() {
      this.toggleLoading()

      try {
        await this.$store.dispatch(CREATE_REWARD_SUGGESTION, this.reward)
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async updateReward() {
      this.toggleLoading()

      try {
        const rewardDiff = diff(this.editReward, this.reward)
        await this.$store.dispatch(UPDATE_REWARD_SUGGESTION, { rewardId: this.editReward.id, payload: rewardDiff })
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    submit() {
      this.validate()

      if (!this.isValid) {
        return
      }

      if (this.isNewReward) {
        this.saveReward()
      } else {
        this.updateReward()
      }
    }
  }
}
</script>

<style lang="scss">
.upload-image {
  label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    margin: auto;
    text-align: center;
    background: #efefef;
  }

  .v-input__control,
  .v-input__slot {
    min-height: 0 !important;
  }
}
</style>
